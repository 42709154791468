body {
  margin: 0;
  padding: 0;
  text-rendering: optimizelegibility;
  height: 100%;
  overflow: hidden;

  .clickable {
    cursor: pointer;
  }

  a {
    &:link,
    &:visited {
      text-decoration: none;
    }
  }

  div.modal-background {
    &.inactive {
      display: none;
      visibility: hidden;
    }

    &.active {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgb(0 0 0 / 50%);
      color: $text-color-light;
      text-align: left;
      z-index: 1500; // style-elements gives some things 1000

      &#logged-out-modal {
        background-color: rgb(0 0 0 / 80%);
        top: 0;
      }
    }

    > .modal {
      background-color: $proda-white;
      border-radius: 10px;
      color: $text-color-dark;
      left: 50%;
      overflow: auto;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      min-width: 500px;
      max-height: 500px;
    }
  }

  #main-file-input {
    display: none;
  }
}

// Hide AGGrid sidebar/toolpanel buttons, because they can't be hidden from javascript
// We currently only use the "select variables" button, and that's moved to the top bar
.ag-side-buttons {
  display: none;
}

input {
  box-shadow: none;
  background: transparent;
  border-radius: 4px;
  color: $text-color-dark;
  border: 1px solid $proda-white-130;
  cursor: text;

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $primary-color;
  }

  &:disabled {
    background-color: $disabled-bg;
    color: $text-color-disabled;
    border: 1px solid $dark;
  }

  // This disables the buttons that increment/decrement a number. Webkit and
  // firefox need different overrides.
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  &[type='number'] {
    appearance: textfield;
  }
}

.hidden {
  display: none;
}

.loading-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacer {
  flex-grow: 1;
}

upload-single-page {
  width: 100%;
  height: calc(100vh - 60px);
}

extraction-validation-page {
  width: 100%;
  height: 100%;
}

file-management-page,
project-overview-page,
property-overview-page,
portfolio-overview-page {
  height: 100%;
}

/* Custom CSS for the Regional Settings Page to make it look nicer quickly */

settings-page,
downloads-page {
  .narrow {
    border: 1px solid $primary-color;
    border-radius: 4px;
    border-top-width: 5px;
    margin: 3rem auto;
    max-width: 30vw;
    min-width: 300px;
    padding: 2rem;
  }

  h2,
  h3 {
    margin-bottom: 0.5em;
  }

  h3 {
    margin-top: 1.5em;
  }

  p {
    color: $text-color-dark;
    font-size: 12px;
    margin-top: 1rem;
  }

  ul {
    padding: 0 1rem;
  }
}

.gm-style-mtc {
  display: flex;
  justify-content: end;
}

html:not([data-headlessui-focus-visible]) *:focus {
  outline: none !important;
}

/* Update Zendesk styles - launcher hidden by default but can be shown via JS */
#launcher {
  display: none;
}

iframe[title='Close message'],
iframe[title='Message from company'] {
  display: none !important;
}
